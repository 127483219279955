

export const header = () => {
  
  const header = document.querySelector('.header');
  const headerBurger = document.querySelector('.js-header-burger');
  const headerNav = document.querySelector('.header__nav');
  const headerInner = document.querySelector('.header__inner');

  headerBurger?.addEventListener('click',function(){
    
    headerBurger.classList.toggle("header__burger_open");
    //headerInner.classList.toggle("header__inner_open");
    headerNav.classList.toggle("header__nav_open");
    header?.classList.add("header_fixed");

    if ( !headerBurger.classList.contains("header__burger_open") && window.pageYOffset < 1 ) {
      header?.classList.remove("header_fixed");
    }

  });

  document.addEventListener('scroll',function(){
    if ( window.pageYOffset > 1 ) {
      header?.classList.add("header_fixed");
    } else {
      header?.classList.remove("header_fixed");
    }
  });


}