console.log('components');


import { swipers } from "./components/swipers";
swipers();

import { header } from "./components/header";
header();

import { scrollTo } from "./components/scrollTo";
scrollTo();

import { podcast } from "./components/podcast";
podcast();