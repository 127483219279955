export const podcast = () => {

  const podcastElements = document.querySelectorAll('.js-podcast-element');
  const podcastDescriptionTitle = document.querySelector('.podcast__description-title');
  const podcastDescriptionText = document.querySelector('.podcast__description-text');
  const podcastWindow = document.querySelector('.podcast__window');

  document.addEventListener('click',function(e){
    if(e.target && e.target.className.indexOf("podcast__play") > -1) {
      document.querySelector(".podcast__play").parentElement.classList.add("podcast__cover_hide");
    }
  });

  if ( podcastElements ) {

    for (let podcast of podcastElements) {
      podcast.addEventListener('click',function(){
        let title = podcast.querySelector(".podcast__name").innerText;
        let description = podcast.querySelector(".podcast__data-description").innerText;
        let src = podcast.dataset.src;
        let img = podcast.dataset.img;


        podcastWindow.innerHTML = 
        `
        <div class="podcast__cover">
            <img src="${img}" alt="">
            <button class="podcast__play"><img src="/img/svg/play.svg" alt=""></button>
        </div>
        <iframe src="${src}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        `

        document.querySelector('.podcast__element_active')?.classList.remove("podcast__element_active");
        podcast.classList.add("podcast__element_active");

        podcastDescriptionTitle.innerText = title;
        podcastDescriptionText.innerText = description;
      });
    }
  
  }


}

