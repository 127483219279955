
import Swiper from 'swiper/bundle';

export const swipers = () => {
 
  var swiperTeams = new Swiper(".js-swiper-teams", {
    slidesPerView: "auto",
    spaceBetween: 40,
    loop: false,
    centeredSlides: false,
    disableOnInteraction: false,
    breakpoints: {
      /* 768: {
        slidesPerView: 3
      },
      991: {
        slidesPerView: 4
      }, */
    },
  });
  
  var swiperSteps = new Swiper(".js-swiper-steps", {
    slidesPerView: "auto",
    spaceBetween: 15,
    loop: false,
    centeredSlides: false,
    disableOnInteraction: false,
    breakpoints: {
      576: {
        spaceBetween: 30,
      },
      /*
      768: {
        slidesPerView: 2.2
      }, */
      /* 991: {
        slidesPerView: 3
      }, */
    },
  });

  var swiperPartners = new Swiper(".js-swiper-partners", {
    slidesPerView: "auto",
    spaceBetween: 30,
    loop: false,
    centeredSlides: false,
    disableOnInteraction: false,
  });

  var swiperPhotos = new Swiper(".js-swiper-photo", {
    slidesPerView: "auto",
    spaceBetween: 30,
    loop: false,
    centeredSlides: false,
    disableOnInteraction: false,
    navigation: {
      prevEl: ".swiper-prev",
      nextEl: ".swiper-next",
    },
  });

}