export const scrollTo = () => {

  const scrollLink = document.querySelectorAll(".js-scroll-to");

  if ( scrollLink ) {

    for(var i = 0; i < scrollLink.length; i++ ){
      scrollLink[i].addEventListener("click", function(e){
        e.preventDefault();
     
        let elementId = this.dataset.scroll;
        let elementGap = 40;
        if ( window.innerWidth < 768 ) { elementGap = 40; }
        let elementPosition = document.getElementById(elementId).offsetTop - elementGap;

        
        window.scrollTo({
          top: elementPosition,
          left: 0,
          behavior: 'smooth',
        });

        if ( document.querySelector(".header__nav_open") ) {
          document.querySelector(".header__nav_open").classList.remove("header__nav_open");
          document.querySelector(".header__burger_open").classList.remove("header__burger_open");
        }

      });
    }

  }

}